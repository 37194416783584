
import {
  userUseCase,
  agentUseCase
} from '@/domain/usecase'
import {
  ref, onMounted, watch, getCurrentInstance, reactive,
  defineComponent
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { TObjSelect } from '@/utils/types/tOptionSelect'
import MultiselectAsync from '@/views/shared-components/select/MultiselectAsync.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import rgxExp from '@/utils/helpers/rgxExp'

export default defineComponent({
  name: 'UserForm',
  components: {
    MultiselectAsync,
    Form,
    Field
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const app = getCurrentInstance()
    const refForm = ref<InstanceType<typeof Form>>()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties

    const getDecript = (): string => route.params.userId as string
    const id = getDecript()

    const schemaForm = Yup.object({
      Username: Yup.string().required('Username harus diisi.')
        .matches(rgxExp.noWhiteSpaceAndSpecialChar, 'Username hanya boleh terdiri dari huruf dan angka.'),
      Password: Yup.string()
        // eslint-disable-next-line no-empty-pattern
        .when('Username', ([], schema) => (id ? schema : schema
          .required('Password tidak boleh kosong.')
          .min(6, 'Password minimal 6 karakter.')
          .max(50, 'Password maksimal 50 karakter.'))),
      Email: Yup.string()
        .required('Email harus diisi.')
        .email('Email tidak valid.'),
      Fullname: Yup.string().required('Nama harus diisi.').min(3, 'Nama minimal 3 karakter.')
        .matches(rgxExp.onlyAlphabetandSpace, 'Nama hanya boleh menggunakan huruf.')
        .max(100, 'Nama maksimal 100 karakter.'),
      GroupUser: Yup.mixed().required('Jabatan harus diisi.'),
      Agent: Yup.mixed().required('Agen harus diisi.'),
      IsActive: Yup.boolean()
    })

    type TSchemaForm = Yup.InferType<typeof schemaForm>

    const dataForm = reactive<TSchemaForm>({
      Username: '',
      Fullname: '',
      Email: '',
      Password: '',
      GroupUser: {},
      IsActive: true,
      Agent: {}
    })

    const statusOption = [{
      id: true,
      name: 'Aktif'
    },
    {
      id: false,
      name: 'Non Aktif'
    }]

    const redirectBack = () => {
      router.go(-1)
    }
    const checkResponse = async (res: {
      error: boolean,
      message: string
    }) => {
      if (res.error) {
        $toast.add({
          severity: 'error',
          detail: res.message,
          group: 'bc',
          life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        if (id) {
          $toast.add({
            severity: 'success',
            // summary: res.result.Header,
            detail: res.message,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        redirectBack()
      }
    }

    const submitProccess = async (values: TSchemaForm) => {
      store.dispatch('showLoading')
      const tempGroupUser = values.GroupUser as TObjSelect
      const tempAgent = values.Agent as TObjSelect
      const payload = {
        Fullname: values.Fullname,
        Username: values.Username,
        Email: values.Email,
        GroupUserId: Number(tempGroupUser?.value),
        IsActive: values.IsActive,
        AdditionalData: {
          Agent: tempAgent.value.toString()
        },
        Password: values.Password
      }
      const response = await userUseCase.saveUser(id, payload)
      checkResponse(response)
    }

    const getDetailAgent = async (username: string) => {
      const {
        result
      } = await agentUseCase.getDetailByUsername(username)
      return result
    }

    const getDataEdit = async () => {
      store.dispatch('showLoading')
      const {
        error,
        result: {
          Email,
          Username,
          Fullname,
          GroupUserNama,
          GroupUserId,
          IsActive
        }
      } = await userUseCase.getDetailUser(Number(id))
      if (!error) {
        const agent = await getDetailAgent(Username)
        refForm.value.setValues({
          Email,
          Fullname,
          Username,
          IsActive,
          GroupUser: GroupUserNama && GroupUserId ? {
            label: GroupUserNama,
            value: GroupUserId
          } : null,
          Agent: agent ? {
            label: agent.Name,
            value: agent.Code
          } : null
        })
      }
      store.dispatch('hideLoading')
    }

    const submitData = (values: TSchemaForm) => {
      submitProccess(values)
    }

    onMounted(() => {
      if (id) {
        getDataEdit()
      }
    })

    return {
      submitProccess,
      redirectBack,
      route,
      router,
      getDecript,
      getDataEdit,
      statusOption,
      store,
      app,
      checkResponse,
      submitData,
      id,
      refForm,
      dataForm,
      schemaForm
    }
  }
})

